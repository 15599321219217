import React from "react";
import Testimonials from "./Testimonials";
import ProjectSection from "./ProjectSection";

function AboutUs() {
  return (
    <div className="py-2">
      <section
        className="ftco-about ftco-section ftco-no-pt ftco-no-pb"
        id="about-section"
      >
        <div className="container">
          <div className="row d-flex no-gutters">
            
            <div className="col-md-12">
              <div className="py-md-5">
                <div className="row justify-content-start pb-3">
                  <div className="col-md-12 heading-section">
                    <h2
                      className="mb-4"
                      style={{ fontSize: "34px", textTransform: "capitalize" }}
                    >
                      About NRI Tax Solutions
                    </h2>
                    <p>
                      We help NRIs making their funds available to them when and
                      where they need it and be fully tax compliant.
                    </p>
                    <p>
                      NRI Tax Solutions is an online service designed and
                      built by NRIs to help and support
                      NRIs with taxation matters for the assets and funds held
                      in India. NRI Tax Solutions is built on last 10 years of
                      experience in managing tax matters for NRIs in. NRI Tax
                      Solutions is aimed at simplifying the process for NRIs and
                      provide a fast and reliable service experience for NRIs.
                      Our UK office will manage all aspects of service on behalf
                      of you while back office in India will work on all the
                      compliance part of it.
                    </p>
                    <p>
                      Our Aim is to provide NRIs a fully tax compliant service
                      and help NRIs to have access to the funds they need
                      without having to go through all the hassle of dealing
                      with numerous offices and personnel in India.
                    </p>
                    <p>
                      As we are based you can contact us anytime and we will
                      be happy to support you with any information. You do not
                      have to email or speak to someone in India.
                    </p>
                    <p>
                      We will not leave things to you to read hundreds of
                      regulation and rules to make out things for you. Our
                      experienced team will handle all complicacies on behalf of
                      you.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Testimonials />
    </div>
  );
}

export default AboutUs;
