import React from "react";
import { Link } from "react-router-dom";

function OrderStatus({ orders }) {
  return (
    <section>
      <div className="container">
        <div className="order-status">
          <h4 className="mb-4">Order Status</h4>
          <table className="w-100 mb-5">
            <thead className="border-dark border-bottom">
              <tr>
                <th>Order Number</th>
                <th>Date</th>
                <th>Description</th>
                <th>Status</th>
                <th>Fee</th>
                <th>Payment Status</th>
              </tr>
            </thead>
            {orders?.length > 0 &&
              <tbody>
                {orders?.map((order, index) => (
                  <tr key={order._id}>
                    <td>
                      <Link to={`/payment/${order.orderId}`} className="underline">
                        {order.orderId}
                      </Link>
                    </td>
                    <td>{new Date(order.createdAt).toLocaleDateString()}</td>
                    <td>{order?.service}</td>
                    <td>{order?.status}</td>
                    <td>£{order?.totalFee || "XXXX"}</td>
                    <td>
                      {order.paymentStatus === "unpaid" ? (
                        <Link to={`/payment/${order.orderId}`} className="underline">
                          Pay Now
                        </Link>
                      ) : (
                        order.paymentStatus
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>}
          </table>
          <div className="documents-section">
            <h4>Documents Uploaded</h4>
            <label
              htmlFor="file-upload"
              className="custom-file-upload cursor-pointer"
            >
              Upload Now
            </label>
            <input type="file" id="file-upload" style={{ display: "none" }} />
            <p>
              <small>
                (Only JPEG, PNG, PDF supported. File size should not be more
                than 5MB)
              </small>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OrderStatus;
