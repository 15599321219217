import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import AboutUs from "./components/AboutUs";
import ContactUs from "./components/ContactUs";
import PanCardServices from "./components/PanCardServices";
import DigitalSignatureCertificate from "./components/DigitalSignatureCertificate";
import TaxFiling from "./components/TaxFiling";
import CashGifts from "./components/CashGifts";
import CapitalGains from "./components/CapitalGains";
import FundsSavingAccount from "./components/FundsSavingAccount";
import CustomerHomepage from "./components/customerHomepage";
import Login from "./components/Login";
import Register from "./components/Register";
import Footer from "./components/Footer";
import "./css/style.css";
import api from '../src/components/api';
import { AuthProvider } from './context/authContext';
import CustomerPayment from "./components/CustomerPayment";
import AdminDashboard from "./components/admin";
import CreateOrder from "./components/CreateOrder";
import UpdateOrder from "./components/UpdateOrder";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/resetPassword";

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState("");
  const [accountDetails, setAccountDetails] = useState(null);

  const handleLogin = (user, token) => {
    sessionStorage.setItem("token", token);
    setLoggedIn(true);
    setUser(user);
    fetchAccountDetails(token);
  };

  const handleLogout = () => {
    api.post('/auth/logout')
      .then((response) => {
      })
      .catch((error) => {
        console.error('Logout error:', error);
      });
    sessionStorage.removeItem("token");
    setLoggedIn(false);
    setUser("");
    setAccountDetails(null);
  };

  const fetchAccountDetails = (token) => {
    setIsLoading(true); 
    api.get('/user/account-details', {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then((response) => {
        setAccountDetails(response.data);
      })
      .catch((error) => {
        console.error('Error fetching account details:', error);
      });
  };

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (token) {
      setLoggedIn(true);
      fetchAccountDetails(token);
    }
  }, []);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkRole = async () => {

      setTimeout(() => {
        setIsLoading(false);
      }, 500); 
    };

    if (loggedIn) {
      checkRole();
    } else {
      setIsLoading(false); 
    }
  }, [loggedIn, accountDetails]);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <div className="spinner-border text-primary" role="status">
        </div>
      </div>
    );
  }


  return (
    <AuthProvider>
      <div
        className="App"
        data-spy="scroll"
        data-target=".site-navbar-target"
        data-offset="300"
      >
        <Navbar accountDetails={accountDetails} onLogout={handleLogout} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/pan-card-services" element={<PanCardServices />} />
          <Route path="/digital-signature-certificate" element={<DigitalSignatureCertificate />} />
          <Route path="/tax-filing" element={<TaxFiling />} />
          <Route path="/cash-gifts" element={<CashGifts />} />
          <Route path="/capital-gains" element={<CapitalGains />} />
          <Route path="/funds-saving-account" element={<FundsSavingAccount />} />
          <Route path="/login" element={loggedIn ? <Navigate to="/dashboard" /> : <Login onLogin={handleLogin} />} />
          <Route path="/register" element={<Register onLogin={handleLogin} />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route
            path="/dashboard"
            element={loggedIn ? (
              accountDetails?.user?.role === "admin" ? (
                <AdminDashboard onLogout={handleLogout} accountDetails={accountDetails} />
              ) : (
                <CustomerHomepage onLogout={handleLogout} accountDetails={accountDetails} />
              )
            ) : (
              <Navigate to="/login" />
            )}
          />
          <Route
            path="/create-order"
            element={loggedIn ? (
              accountDetails?.user?.role === "admin" ? (
                <CreateOrder />
              ) : (
                <Navigate to="/login" />)
            ) : (
              <Navigate to="/login" />
            )}
          />
          <Route path="/payment/:orderId" element={loggedIn ? (<CustomerPayment />) : (
            <Navigate to="/login" />
          )} />
          <Route path="/order/:orderId" element={loggedIn ? (<UpdateOrder />) : (
            <Navigate to="/login" />
          )} />
          <Route path="/forgot-password" element={ <ForgotPassword />} />
        </Routes>
        <Footer />
      </div>
    </AuthProvider>
  );
}

export default App;
